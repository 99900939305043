// BG Images
export const BG_COVER = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Cover%20Portrait.jpg?updatedAt=1709266045770`;
export const BG_WISHLIST = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-wishlist_2i8I7jb5h.jpg?updatedAt=1638541154280`;
export const BG_RSVP = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20RSVP.jpg?updatedAt=1709266045654`;
export const BG_LIVESTREAM = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-pre-livestream_REXdy4RJy.jpg?updatedAt=1638541152599`;
export const BG_FOOTER = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Footer.jpg?updatedAt=1709266045780`;
export const BG_START_JOURNEY = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-start-journey_DwkmI92EvqJ.jpg?updatedAt=1638541149699`;
export const BG_COUNTING = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Counting%20Days.jpg?updatedAt=1709266045018`;
export const BG_LOVESTORY = `https://user-images.githubusercontent.com/10141928/146198254-9f2df09b-7cc7-4cd9-bdce-bd0563a23a77.jpg`;
export const BG_GIFTCARD = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Tanda%20Kasih%20Revisi%202.jpg?updatedAt=1710440619633`;
import igFilter from '@/assets/IG_FILTER.jpg';
export const BG_FILTER = igFilter;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Cover%20Desktop%20Revisi.jpg?updatedAt=1710385017731`;

// The Bride & How We Meet Images
export const IMG_MAN = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Groom.jpg?updatedAt=1709280592560`;
export const IMG_GIRL = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Bride.jpg?updatedAt=1709280499508`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

// Cover Gallery Images
export const IMG_COVER = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Cover%20Dalam%20Galeri.jpg?updatedAt=1709266045394`;
export const IMG_SLIDE_1 = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Gallery%20Landscape%201%20Revisi.jpg?updatedAt=1710384754919`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/Foto%20Gallery%20Landscape%202%20Revisi.jpg?updatedAt=1710384754886`;

// Health Protocol Images
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/26963539/170411241-58ade9a9-1349-4391-96fa-770128496ed5.jpg`,
  en: `https://user-images.githubusercontent.com/26963539/170411295-b2e6ff6f-a854-4b08-a29f-5dc2fc534b2d.jpg`,
};

// Logo pengantin
import ImgLogo from '@/assets/icons/logo.png'
export const IMG_LOGO = ImgLogo;

// Don't change with cdn image
import ImgQRCover from '@/images/qrcover.jpg'
export const IMG_QR_COVER = ImgQRCover;

// Global assets
export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
export const PARTNER_DALANG_LOGO = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const PARTNER_PENTONE_LOGO = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;